


import { mapGetters } from 'vuex';
import Vue from 'vue';
import { TEventStat } from '@/_modules/statistics/types/event-stat.type';
import { TColumns } from '@/_modules/statistics/types/column.type';
import { TEventStatRow } from '@/_modules/statistics/types/event-stat/event-stat-row.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import FileHelper from '@/_helpers/file.helper';

type TEventStatData = {
  columns: TColumns[];
  rows: TEventStatRow[];
  contacts: {
    contact_total: number;
    contact_views: number;
  };
  meetings: {
    meetings_confirmed: number;
    meeting_total: number;
  };
  messages: {
    message_total: number;
  };
  notes: {
    note_total: number;
  };
  promopage: {
    promopages_total: number;
    promopages_views: number;
  };
}

interface IEventStatMethods {
  setClass: (name: string) => string | void;
  setData: () => void;
  exportEventContacts: () => void;
}

type TEventStatComputed = {
  eventStat: TEventStat;
  isLoadingEvent: boolean;
  userInfo: TUser;
  eventId: number;
  eventInfo: TEvent;
  canSeeEventStatistics: boolean;
  isLoading: boolean;
}

const StatisticsCompany = Vue.extend<TEventStatData, IEventStatMethods, TEventStatComputed>({
  name: 'statistics-event',
  computed: {
    ...mapGetters('statisticStore', {
      eventStat: 'event',
      isLoadingEvent: 'isLoadingEvent'
    }),
    ...mapGetters('_userStore', {
      userInfo: 'user'
    }),
    ...mapGetters('_eventStore', {
      eventInfo: 'event',
    }),
    eventId(): number {
      return Number(this.$route.params.eventId);
    },
    canSeeEventStatistics(): boolean {
      return this.eventInfo && this.eventInfo.personal.is_creator;
    },
    isLoading(): boolean {
      return this.isLoadingEvent || !this.canSeeEventStatistics;
    }
  },
  watch: {
    eventId: {
      immediate: true,
      async handler(): Promise<void> {
        const eventInfo = await this.$store.dispatch('_eventStore/getEvent', this.eventId);
        if (!eventInfo || !eventInfo.personal.is_creator) {
          this.$router.push({ name: 'result-personal' }).catch();
          return;
        }
        this.$store.dispatch('statisticStore/getEvent', { eventId: this.eventId });
      }
    },
    eventStat: {
      immediate: true,
      handler(): void {
        this.setData();
      },
    },
    '$route.params.lang': {
      handler(): void {
        this.columns.forEach(item => {
          if (item.field === 'contacts') {
            item.label = this.$t('result.event.contacts');
          }
          if (item.field === 'photos') {
            item.label = this.$t('result.event.photos');
          }
          if (item.field === 'files') {
            item.label = this.$t('result.event.files');
          }
          if (item.field === 'products') {
            item.label = this.$t('result.event.products');
          }
          if (item.field === 'comments') {
            item.label = this.$t('result.event.comments');
          }
          if (item.field === 'views') {
            item.label = this.$t('result.event.views');
          }
          if (item.field === 'favorites') {
            item.label = this.$t('result.event.favorites');
          }
          if (item.field === 'messages') {
            item.label = this.$t('result.event.messages');
          }
          if (item.field === 'meetings') {
            item.label = this.$t('result.event.meetings');
          }
          if (item.field === 'meetings_held') {
            item.label = this.$t('result.event.meetings_held');
          }
        });
      },
    },
  },
  data(): TEventStatData {
    return {
      columns: [
        {
          label: this.$t('result.event.company_name'),
          field: 'name',
        },
        {
          label: this.$t('result.event.contacts'),
          field: 'contacts',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.photos'),
          field: 'photos',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.files'),
          field: 'files',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.products'),
          field: 'products',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.comments'),
          field: 'comments',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.views'),
          field: 'views',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.favorites'),
          field: 'favorites',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.messages'),
          field: 'messages',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.meetings'),
          field: 'meetings',
          type: 'number',
          extraClasses: 'left-border',
        },
        {
          label: this.$t('result.event.meetings_held'),
          field: 'meetings_held',
          type: 'number',
          extraClasses: 'left-border',
        },
      ],
      rows: [],

      contacts: {
        contact_total: 0,
        contact_views: 0,
      },
      meetings: {
        meetings_confirmed: 0,
        meeting_total: 0,
      },
      messages: {
        message_total: 0,
      },
      notes: {
        note_total: 0,
      },
      promopage: {
        promopages_total: 0,
        promopages_views: 0,
      },
    };
  },
  methods: {

    async exportEventContacts(): Promise<void> {
      const file = await this.$store.dispatch('statisticStore/exportEventContacts', { eventId: this.eventId });
      FileHelper.downloadFile(file, 'Biletum_event_' + this.eventId + '_contacts_statistics.csv');
    },

    setClass(name: string): string | void {
      if (name) {
        if (
          name === 'photos'
          || name === 'comments'
          || name === 'messages'
        ) {
          return 'left-border total-color';
        }
        if (
          name === 'files'
          || name === 'views'
          || name === 'meetings'
        ) {
          return 'left-border high-color ';
        }
        if (name === 'contacts'
          || name === 'products'
          || name === 'meetings_held') {
          return 'left-border low-color';
        }
      }
    },
    setData(): void {

      if (!this.eventStat) {
        return;
      }

      this.contacts.contact_total = this.eventStat.contact_total;
      this.contacts.contact_views = this.eventStat.contact_views;
      this.meetings.meetings_confirmed = this.eventStat.meetings_confirmed;
      this.meetings.meeting_total = this.eventStat.meeting_total;
      this.messages.message_total = this.eventStat.message_total;
      this.notes.note_total = this.eventStat.note_total;
      this.promopage.promopages_total = this.eventStat.promopages_total;
      this.promopage.promopages_views = this.eventStat.promopages_views;

      this.eventStat.list.forEach(item => {

        const obj = {
          name: item.promopage_title,
          contacts: item.stat.contacts,
          photos: item.stat.photos,
          files: item.stat.files,
          products: item.stat.products,
          comments: item.stat.comments,
          views: item.stat.views,
          favorites: item.stat.favorites,
          messages: item.stat.messages,
          meetings: item.stat.meetings,
          meetings_confirmed: item.stat.meetings_confirmed,
        };

        this.rows.push(obj);

      });
    },
  },
});

export default StatisticsCompany;
